<template>
    <div>
        <CDropdown
            v-if="active"
            nav
            :togglerText="active.NAME"
            placement="bottom-end"
        >
            <CDropdownItem @click="changeApi(api)" v-for="api in apis" :key="api.API_URL">
                <img :src="`${api.API_URL}/images/favicon/icon-72x72`" alt="Icone sistema" class="api-icon">
                {{ api.NAME }}
            </CDropdownItem>
        </CDropdown>
    </div>
</template>

<script>
  import config from '../config';
  import _find from 'lodash/find';

  export default {
    name: "ApiUrlChange",
    data() {
      return {
        apis: {},
        active: null,
      }
    },
    methods: {
      changeApi(api) {
        localStorage.setItem('api_url', api.API_URL);
        localStorage.setItem('cdn_url', api.CDN_URL);
        localStorage.setItem('revalidar_carga', 1);
        window.location.reload();
      }
    },
    beforeMount() {
      this.active = _find(config.apis, { API_URL: config.api } );
      this.apis = config.apis;
    }
  }
</script>

<style scoped>
    .api-url {
        display: block;
        float-wrap: wrap;
    }
    .api-icon {
        max-width: 20px;
        max-height: 20px;
        margin-right: 10px;
    }
</style>