<template>
  <CHeader fixed with-subheader light>
    <CToggler
      in-header
      :class="{'d-lg-none': !!show, 'ml-3': true}"
      @click="toggleShowHide"
    />
    <CHeaderBrand :class="{'d-lg-none': !!show, 'mx-auto': true}" to="/">
      <img v-bind:src="logo" alt="logo" class="menu_logo">
    </CHeaderBrand>
    <CHeaderNav :class="{'d-md-down-none': true, 'mr-auto': show}">
      <!--<CHeaderNavItem class="px-3">-->
        <!--<CHeaderNavLink to="/dashboard">-->
          <!--Dashboard-->
        <!--</CHeaderNavLink>-->
      <!--</CHeaderNavItem>-->
    </CHeaderNav>
    <CHeaderNav class="mr-4">
      <ApiUrlChange />
      <CDropdown
        nav
        :togglerText="colaborador.nome"
        placement="bottom-end"
      >
        <CDropdownItem @click="logout">
          Deslogar
        </CDropdownItem>
      </CDropdown>
    </CHeaderNav>
    <!--<CSubheader class="px-3">-->
      <!--<CBreadcrumbRouter class="border-0 mb-0"/>-->
    <!--</CSubheader>-->
  </CHeader>
</template>

<script>
  import config from '../../config'
  import ApiUrlChange from "../ApiUrlChange";

  export default {
    name: 'TheHeader',
    components: {
      ApiUrlChange
    },
    props: [
      'show',
      'toggleShowHide'
    ],
    data () {
      return {
        logo: config.api+'/images/logo',
        loading: false,
        colaborador: {
          nome: ''
        }
      }
    },
    methods: {
      logout () {
        localStorage.clear()
        this.loading = false
        this.$router.push('/login')
      }
    },
    beforeMount() {
      const {colaborador} = (JSON.parse(localStorage.getItem("app")));
      this.colaborador = colaborador;
    },
  }
</script>